import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "store/auth/userSlice";
import { clearSettingData } from "store/settings/settingsSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import axiosInstance from "apiServices/axiosInstance";

import { setThemeColor, setThemeColorLevel } from "store/theme/themeSlice";
import { themeConfig } from "configs/theme.config";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { token, expired } = useSelector((state) => state.auth.session);

  const onThemeColorChange = (value) => {
    dispatch(setThemeColor(value));
  };

  const onThemeColorLevelChange = (value) => {
    dispatch(setThemeColorLevel(value));
  };

  //signIn endpoint
  const signIn = async ({ email, password }) => {
    try {
      const formData = {
        email: email,
        password:password,
      };
      const response = await axiosInstance.post("admin/admin-sign-in",formData);
      // console.log("🚀 ~ signIn ~ response:", response)
      if (response.status) {
        const { token, data } = response;
        dispatch(onSignInSuccess(token));
        const userData = data;
        // console.log("userData:::",userData)
        // const settingsData = data.settingsData;
        userData &&
          dispatch(
            setUser({
              avatar: userData.avatar
                ? userData.avatar
                : "https://espo-live.s3.us-west-1.amazonaws.com/content/images/profile/admin/39898590219153357565.webp",
              admin_name: userData.admin_name ? userData.admin_name : "Guest",
              email: userData.admin_email
                ? userData.admin_email
                :"demo@gmail.com",
              authority: userData.role
                ? [userData.role.toLowerCase()]
                : ["admin"],
              admin_id: userData.admin_id ? userData.admin_id : 0,
              password: userData.password ? userData.password : "",
            })
          );
        // onThemeColorChange(
        //   data?.theme[0] ? data.theme[0] : themeConfig.themeColor
        // );
        // onThemeColorLevelChange(
        //   data?.theme[1] ? data.theme[1] : themeConfig.primaryColorLevel
        // );
        //   &&
        //   dispatch(setSettingData(settingsData));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return response;
      } else {
        return response;
      }
    } catch (errors) {
      // console.log("signIn error:", errors);
      return {
        status: false,
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  //handle of  signOut 
  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
    dispatch(clearSettingData());
    onThemeColorChange(themeConfig.themeColor);
    onThemeColorLevelChange(themeConfig.primaryColorLevel);
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  //check user login or not
  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };

  return {
    authenticated: token && signIn && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
